import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Alert } from 'react-bootstrap';

import './ship-icon.scss';
import { CustomPopover } from './custom-popover';
import { IShipNode } from '../../../common/model/graphql-types';
import { CSShipType } from './ship-type';
import { RatingBox } from '../../../common/components/rating-box';

interface IPopoverProps {
  ship: IShipNode;
}

const ShipPopover: React.FC<IPopoverProps> = ({ ship }) => {
  const image = ship.smallAvatar
    ? getImage(ship.smallAvatar.localFile.childImageSharp)
    : null;
  return (
    <div className="employee-popover">
      <div className="top">
        <div className={`avatar ${ship.rarity}`}>
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${ship.fullName}`}
          />
        </div>
        <p className="name">
          <span className="emp-name">{ship.fullName}</span>{' '}
          {ship.showNewTag && <span className="tag new">NEW!</span>}
        </p>
        <div className="type-class ship">
          <CSShipType type={ship.class} />
        </div>
      </div>
      {!ship.areRatingsNotFinal ? (
        <div className="bottom">
          <RatingBox
            game="cs-old"
            rating={ship.pveScore}
            ratingName="PVE"
            customClass="kr-character"
          />
          <RatingBox
            game="cs-old"
            rating={ship.pvpScore}
            ratingName="PVP"
            customClass="kr-character"
          />
        </div>
      ) : (
        <Alert variant="primary">
          <p>
            This ship was recently released in KR and we need time to properly
            test his/her abilities before reviewing and rating him/her.
          </p>
        </Alert>
      )}
    </div>
  );
};

interface IProps {
  ship: IShipNode;
  showLabel?: boolean;
  enablePopover?: boolean;
}

export const ShipIcon: React.FC<IProps> = ({
  ship,
  showLabel,
  enablePopover
}) => {
  const image = ship.smallAvatar
    ? getImage(ship.smallAvatar.localFile.childImageSharp)
    : null;
  return (
    <CustomPopover
      enabled={enablePopover}
      customClass="character-hover-box"
      popover={<ShipPopover ship={ship} />}
    >
      <Link to={'/counter-side/ships/' + ship.slug}>
        <div className={`avatar ${ship.rarity} ${showLabel}`}>
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${ship.name}`}
          />
        </div>
        {showLabel && <span className="emp-name">{ship.shortName}</span>}
      </Link>
    </CustomPopover>
  );
};
