import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import './employee-role.scss';

interface IProps {
  type: string;
}

export const CSShipType: React.FC<IProps> = ({ type }) => {
  const popoverType = (
    <Popover id="popover-type">
      <Popover.Header as="h3">{type}</Popover.Header>
      <Popover.Body>
        {type === 'Assault' && (
          <>A standard, versatile ship. Can be added to Assault locations.</>
        )}
        {type === 'Armored' && (
          <>
            An armored ship with penetrating power. Can move across 1 more line
            horizontally.
          </>
        )}
        {type === 'Auxillary' && (
          <>
            A special ship with superb turning power. Can move 1 more cell
            diagonally.
          </>
        )}
        {type === 'Cruiser' && (
          <>
            A high-speed, high-mobile ship. Can move 1 more cell in a straight
            line.
          </>
        )}
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      placement="auto-start"
      overlay={popoverType}
    >
      <div className="employee-role ship">
        {type === 'Assault' && (
          <span>
            <StaticImage
              src="../../../../images/counterside/icons/ship_assault.png"
              alt="Assault"
            />
          </span>
        )}
        {type === 'Armored' && (
          <span>
            <StaticImage
              src="../../../../images/counterside/icons/ship_cruiser.png"
              alt="Armored"
            />
          </span>
        )}
        {type === 'Auxillary' && (
          <StaticImage
            src="../../../../images/counterside/icons/ship_aux.png"
            alt="Auxillary"
          />
        )}
        {type === 'Cruiser' && (
          <StaticImage
            src="../../../../images/counterside/icons/ship_cruiser.png"
            alt="Cruiser"
          />
        )}
        <span>{type}</span>
      </div>
    </OverlayTrigger>
  );
};
