import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { IShipNode } from '../../../common/model/graphql-types';
import { ShipIcon } from './ship-icon';

interface IProps {
  id?: string;
  slug?: string;
  enablePopover?: boolean;
  showLabel?: boolean;
  hideTags?: boolean;
  mode: string;
  unitId?: string;
}

export const Ship: React.FC<IProps> = ({
  id,
  unitId,
  mode,
  showLabel,
  slug,
  enablePopover
}) => {
  const data = useStaticQuery(graphql`
    query {
      allShips: allContentfulShip {
        nodes {
          id
          unitId
          fullName
          slug
          shortName
          pveScore
          pvpScore
          pveScoreNumber
          pvpScoreNumber
          areRatingsNotFinal
          smallAvatar {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          rarity
          regions
          class
        }
      }
    }
  `);
  if ((!id && !slug && !unitId) || !mode) {
    return <div>Err</div>;
  }

  const ship = data.allShips.nodes.find((emp: IShipNode) =>
    id ? emp.id === id : unitId ? emp.unitId === unitId : emp.slug === slug
  );

  if (!ship) {
    return <div>Err</div>;
  }

  return (
    <>
      {mode === 'icon' && (
        <ShipIcon
          ship={ship}
          showLabel={showLabel}
          enablePopover={enablePopover}
        />
      )}
    </>
  );
};
